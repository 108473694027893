import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

  const maxConcurrentLoads = 5; // Maximum number of tabs that can be loaded at the same time


const tabConfig = [
  { label: "HKEJ", url: "https://www1.hkej.com/dailynews/headline" },
  { label: "Tech", url: "https://www.theinformation.com/features/exclusive" },
  { label: "Crypto", url: "https://crypto.news/news/" },
  { label: "AI", url: "https://www.artificialintelligence-news.com/artificial-intelligence-news/" },
  { label: "HKFINT", url: "https://fintechnews.hk/" },
  { label: "UX", url: "https://ux-news.com//" },
  { label: "psycho", url: "https://www.sciencedaily.com/news/mind_brain/psychology/" },
  { label: "health", url: "https://www.sciencedaily.com/news/top/health/" },
  { label: "math", url: "https://www.sciencedaily.com/news/computers_math/" },
  { label: "Physical", url: "https://www.sciencedaily.com/news/top/technology/" },
];

export default function BasicTabs() {

  const [value, setValue] = React.useState(0);
  const [content, setContent] = React.useState({});
  const [loading, setLoading] = React.useState({});
  const [error, setError] = React.useState({});
  const [loadingStartTime, setLoadingStartTime] = React.useState({});
  const [loadingFinishTime, setLoadingFinishTime] = React.useState({});

  const [countdown, setCountdown] = React.useState(-1); // Countdown from 5 seconds
  const [countdownActive, setCountdownActive] = React.useState(false); // Is the countdown active?
  const [autoLoadEnabled, setAutoLoadEnabled] = React.useState(false); // Is auto-load allowed to start?
  const [autoLoadingActive, setAutoLoadingActive] = React.useState(false);


function CustomTabPanel(props) {
  const { content, value, index, ...other } = props;
  const placeholder = "DOUBLE_LINE_BREAK_PLACEHOLDER"; // Choose a unique placeholder
  const preprocessedContent = content
    .replace(/<br \/><br \/>/gi, placeholder) // Replace double line breaks with placeholder
    .replace(/<br \/>/gi, "<br /><br />") // Replace remaining single line breaks with double
    .replace(new RegExp(placeholder, "gi"), "<br /><br />"); // Revert placeholder back to a single line break

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`custom-tabpanel-${index}`}
      aria-labelledby={`custom-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div" dangerouslySetInnerHTML={{ __html: preprocessedContent }}></Typography>
          {loadingFinishTime[index] && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Loaded at: {loadingFinishTime[index]}
            </Typography>
          )}

           </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  content: PropTypes.string,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    style:{fontSize:"12px"}
  };
}


  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Skip fetching for the "State" tab
    if (tabConfig[newValue].special) return;

    const tabContent = content[newValue];
    const hasError = error[newValue];
    if (tabContent === undefined || hasError) { // Allow reload if content has not been loaded or if there was an error
      if (!loading[newValue]) {
        fetchTabContent(tabConfig[newValue].url, newValue);
      }
    }
  };



React.useEffect(() => {
  if (countdown > 0 && countdownActive) {
    const timerId = setTimeout(() => setCountdown(countdown - 1), 1000);
    return () => clearTimeout(timerId);
  } else if (countdown === 0 && countdownActive) {
    // Countdown completed, allow auto-loading to start
    setAutoLoadEnabled(true);
    setAutoLoadingActive(true); // Ensure to activate auto-loading
    initiateAutoLoad(); // Now start auto-loading
  }
}, [countdown, countdownActive]);
  React.useEffect(() => {
    // Check if there's at least one loading operation that has finished
    const hasCompletedLoading = Object.values(loading).includes(false);

    if (hasCompletedLoading) {
      // At least one loading operation has finished, trigger the next load
      loadNextTab();
    }
  }, [loading]); // Dependency array ensures effect runs on `loading` state changes


  const preloadContentSequentially = async (index = 0) => {
    if (index >= tabConfig.length) return; // Stop the recursion when all tabs are loaded

    const tab = tabConfig[index];
    if (!content[index] && !loading[index] && !error[index]) {
      await fetchTabContent(tab.url, index); // Await ensures each fetch completes before the next starts
    }

    setTimeout(() => preloadContentSequentially(index + 1), 500); // Load next tab after a slight delay
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      // Trigger an update to re-render the component, updating displayed durations
      setLoadingStartTime(prev => ({ ...prev }));
    }, 1000); // Update every second

    return () => clearInterval(interval); // Clear the interval when the component unmounts
  }, []);


  const fetchTabContent = async (url, index) => {
    setLoading(prev => ({ ...prev, [index]: true }));
    setLoadingStartTime(prev => ({ ...prev, [index]: Date.now() })); // Record start time
    setError(prev => ({ ...prev, [index]: false })); // Reset error status before fetching

    try {
      const response = await fetch(`https://basic-bundle-silent-rain-ff56.iching.workers.dev/extract?src=${url}&n=10`);
      if (!response.ok) throw new Error('Network response was not ok');
      const jsonData = await response.json();
      
      // Check for specific error responses in jsonData and handle accordingly
      if (jsonData.error) {
        throw new Error(jsonData.error.message);
      }

      const rawContent = jsonData.choices[0].message.content;
      const formattedContent = rawContent.replace(/\n/g, '<br />');
      
      setContent(prevContent => ({ ...prevContent, [index]: formattedContent }));
    } catch (error) {
      console.error("Failed to fetch tab content", error);
      setContent(prevContent => ({ ...prevContent, [index]: "Failed to load content. Error: " + error.message }));
      setError(prev => ({ ...prev, [index]: true })); // Mark that an error occurred
    } finally {
      setLoadingFinishTime(prevFinishTimes => ({
        ...prevFinishTimes,
        [index]: new Date().toLocaleString()
      }));
      setLoading(prev => ({ ...prev, [index]: false }));      
    }
  };


const handleButtonClick = () => {
  if (countdownActive && countdown > 0) {
    // Stop the countdown and prevent auto-load from automatically starting
    setCountdownActive(false);
    setAutoLoadEnabled(false);
  } else if (autoLoadingActive) {
    // Stop the ongoing auto-loading process
    setAutoLoadingActive(false);
  } else {
    // Restart the auto-loading process
    setAutoLoadingActive(true);
    setAutoLoadEnabled(true); // Ensure auto-load is enabled
    initiateAutoLoad();
  }
};


React.useEffect(() => {
  // This code runs after `autoLoadingActive` has been updated
  if (autoLoadingActive) {
    loadNextTab();
  }
}, [autoLoadingActive]); // Dependency array ensures effect runs on `autoLoadingActive` changes

  const handleStopAutoLoad = () => {
    setAutoLoadEnabled(false); // Disable auto-load
    setCountdown(-1); // Stop the countdown
  };

const loadNextTab = () => {
      if (!autoLoadEnabled || !autoLoadingActive) {
        return
      }

        // Determine tabs eligible for loading (not currently loading and no content loaded)
      const eligibleForLoading = tabConfig.slice(0) // Exclude "State" tab
        .map((_, index) => index + 0) // Adjust index for actual tab positions
        .filter(index => !loading[index] && !content.hasOwnProperty(index));

      // Calculate how many tabs can start loading based on the concurrency limit
      const loadableCount = Math.min(maxConcurrentLoads - Object.values(loading).filter(Boolean).length, eligibleForLoading.length);

      // Initiate loading for eligible tabs, respecting the concurrency limit
      eligibleForLoading.slice(0, loadableCount).forEach(index => {
        if (!loading[index]) { // Double-check to avoid race conditions
          fetchTabContent(tabConfig[index].url, index);
        }
      });
    };

const initiateAutoLoad = () => {
  // Ensure auto-loading is both enabled and active before proceeding
  if (autoLoadEnabled) {
    console.log("initiateAutoLoad")
    setAutoLoadingActive(true); // Confirm auto-loading is active
    loadNextTab(); // Start loading the next tab
  }
};

return (
  <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', width: '100%', height: 'auto' }}>
    <Box sx={{ width: '10%', minWidth: '100px', borderRight: 1, borderColor: 'divider' }}>
      <Box sx={{ p: 0 ,width:"80%", margin:"10px auto"}}>

        <Button 
          variant="contained" 
          onClick={handleButtonClick}
          sx={{ fontSize: '12px' }}
        >
          {countdownActive && countdown > 0 ? `Stop Auto-Load (${countdown})` :
            autoLoadingActive ? 'Stop Auto-Load' : 'Start Auto-Load'}
        </Button>

      </Box>
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      sx={{ borderRight: 1, borderColor: 'divider' }}
    >
      {tabConfig.map((tab, index) => {
        const isLoading = loading[index];
        const isLoaded = content.hasOwnProperty(index) && !error[index];
        const loadStartTime = loadingStartTime[index];
        const now = Date.now();
        const duration = isLoading && loadStartTime ? `${((now - loadStartTime) / 1000).toFixed(0)}` : '';
        const label = `${tab.label} ${isLoading ? `(${duration})` : isLoaded ? '' : ''}`;

        // Apply conditional styling
        const labelStyle = {
          color: !isLoaded  ? 'grey' : 'inherit', // Dim the text if not loaded and not currently loading
          opacity: !isLoaded && !isLoading ? 0.5 : 1,
        };

        return (
          <Tab key={index} label={label} {...a11yProps(index)} sx={labelStyle} />
        );
      })}
    </Tabs>
    {Object.values(loading).some(isLoading => isLoading) && (
      <Box sx={{ p: 0 ,width:"100%", margin:"10px auto", textAlign:"center"}}>
        <CircularProgress />
      </Box>
    )}
    </Box>

{tabConfig.map((tab, index) => {
  // Check if this tab is currently loading
  const isLoading = loading[index];
  // Check if content is loaded and no error
  const isLoaded = content.hasOwnProperty(index) && !error[index];
  // Get the current content if available
  const currentContent = content[index]; 
  // Get the loading start time for this tab
  const startTime = loadingStartTime[index];
  // Calculate the loading duration
  const now = Date.now();
  const loadingDuration = startTime ? ((now - startTime) / 1000).toFixed(1) : 0; // in seconds

  let displayContent;
  if (isLoading) {
    // Content is currently loading, include the loading duration
    displayContent = `Loading content... (${loadingDuration}s)`;
  } else if (isLoaded && currentContent) {
    // Content is loaded, show it
    displayContent = currentContent;
  } else {
    // Content is not loaded and not currently loading
    displayContent = "Select a tab to load content.";
  }

  // Process the content for better readability if needed
  const placeholder = "DOUBLE_LINE_BREAK_PLACEHOLDER"; // Choose a unique placeholder
  const preprocessedContent = displayContent
    .replace(/<br \/><br \/>/gi, placeholder) // Replace double line breaks with placeholder
    .replace(/<br \/>/gi, "<br /><br />") // Replace remaining single line breaks with double
    .replace(new RegExp(placeholder, "gi"), "<br />"); // Revert placeholder back to a single line break

  return (
    <CustomTabPanel key={index} value={value} index={index} content={preprocessedContent} />
  );

})}

  </Box>
);

}